<template>
  <v-form
    v-model="valid"
    ref="form"
    class="login_form"
    @submit.prevent="submit"
  >
    <div
      class="v-input__control d-flex flex-row justify-center alert-container"
      v-if="errMsg"
    >
      <v-alert text outlined class="red-alert" height="auto">
        {{ errMsg }}
      </v-alert>
    </div>

    <v-text-field
      v-model="form.email"
      :rules="emailRules"
      name="email"
      :label="$t('email')"
      class="custom-input-field login_email ml-auot"
    />

    <v-text-field
      v-model="form.password"
      :append-icon="showPass ? 'mdi-eye' : 'mdi-eyeOff'"
      :rules="passwordRules"
      :type="showPass ? 'text' : 'password'"
      class="custom-input-field login_pass"
      :label="$t('password')"
      @click:append="showPass = !showPass"
    ></v-text-field>

    <div
      class="v-input__control d-flex flex-row justify-center mb-7"
      v-if="loginAttempts >= 3"
    >
      <vue-recaptcha
        ref="vueRecaptcha"
        :language="$i18n.locale"
        :loadRecaptchaScript="true"
        :sitekey="recaptchaSitekey"
        @verify="recaptchaRespond"
      ></vue-recaptcha>
    </div>

    <div class="v-input__control d-flex flex-row justify-center">
      <v-btn
        type="submit"
        :disabled="!valid"
        :loading="loading"
        color="#6BC0A5"
        @click="validate"
      >
        {{ $t("login") }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { mapActions } from "vuex";

export default {
  name: "LoginForm",

  data() {
    return {
      showPass: false,
      valid: false,
      loading: false,
      errMsg: null,
      form: {
        email: "",
        password: "",
      },
      emailRules: [
        (v) => !!v || this.$t("email_required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("email_must_be_valid"),
      ],
      passwordRules: [(v) => !!v || this.$t("password_required")],
      recaptchaSitekey: process.env.VUE_APP_RECAPTCHA_KEY,
      recaptchaToken: null,
      loginAttempts: 0,
    };
  },

  components: {
    VueRecaptcha,
  },

  watch: {
    loginAttempts(newVal, oldVal) {
      if ((newVal >= 3) & !this.recaptchaToken) {
        this.valid = false;
      }
    },
    form: {
      handler(newVal) {
        if ((this.loginAttempts >= 3) & !this.recaptchaToken) {
          this.valid = false;
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    recaptchaRespond(res) {
      // TO DO integrate with the Bacend with this token
      this.recaptchaToken = res;
      this.valid = true;
    },
    submit() {
      this.loading = true;

      this.login(this.form)
        .then((res) => {
          this.loading = res.loading;
          this.errMsg = res.errMsg;

          setTimeout(() => {
            try {
              this.$store.dispatch("logActivity", {
                tag: "login",
                type: 1,
              });

              return this.$router
                .push({
                  name: "members-list",
                  params: {
                    lang: this.$i18n.locale,
                  },
                })
                .catch(() => {});
            } catch (error) {
              // intentionally empty
              // console.log(error);
            }
          }, 500);
        })
        .catch((e) => {
          this.loading = false;

          if (e?.response?.status == 401 || e?.response?.status == 429) {
            this.loginAttempts++;
            this.recaptchaReset();
          }

          this.showLoginErrorMsg(e);
        });
    },
    validate() {
      this.$refs.form.validate();
    },
    showLoginErrorMsg(e) {
      if (e?.response?.status == 401) {
        return (this.errMsg = this.$t("wrong_email"));
      }

      if (e?.response?.status == 429) {
        return (this.errMsg = this.$t("too_many_attempts"));
      }

      this.errMsg = this.$t("something_went_wrong");
    },
    recaptchaReset() {
      this.recaptchaToken = null;
      if (this.$refs.vueRecaptcha) {
        this.$refs.vueRecaptcha.reset();
      }
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/login-form.scss"></style>
